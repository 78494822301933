import React, { useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import {
  withStyles,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  Typography,
  Avatar,
  Link
} from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import { withRouter } from 'react-router-dom'
import {
  getImageUrls,
  getInitials,
  history,
  toLocalDateFormat
} from '../../utils'
import { useConfirm } from 'material-ui-confirm'
import { AccessControl } from '../../components/Utils/AccessControl'
import { Page, SkeletonTable } from 'components'
import EmptyFirstMembershipPlan from './components/EmptyFirstMembershipPlan/EmptyFirstMembershipPlan'
import constants from 'theme/constants'
import {
  useMutationMembershipPlanDelete,
  useMembershipPlanList
} from 'hooks-querys'
import { MembershipPlan } from 'types/api'
import ShareIcon from '@material-ui/icons/Share'
import { toast } from 'react-toastify'
import { genericOnShare } from 'utils/genericOnShare'

const styles = () => ({
  root: {},
  paper: {
    ...constants.shadowCard
  },
  avatar: {
    width: 90,
    height: 55,
    borderRadius: 5
  }
})

// @ts-ignore
const MembershipPlanList = (props) => {
  const confirm = useConfirm()
  const dispatch = useDispatch()
  const { classes } = props
  const mutationMembershipPlanDelete = useMutationMembershipPlanDelete()

  const estabelecimentoId = useSelector(
    // @ts-ignore: //ainda não foi tipado o redux state
    (state) => state.usuarioEstabelecimento.estabelecimentoId
  )
  const { isLoading, data: membershipPlan } = useMembershipPlanList(
    estabelecimentoId
  )

  const handleDeleteClick = (idMembershipPlan: number) => {
    confirm({
      title: 'Você deseja excluir esse plano de membros?',
      //description: 'Confirmando essa operação, plano será excluido para sempre!',
      confirmationText: 'Sim, excluir!',
      cancellationText: 'Nãããoo!'
    }).then(() => {
      deleteMembershipPlan(idMembershipPlan)
    })
  }

  const deleteMembershipPlan = (idMembershipPlan: number) => {
    mutationMembershipPlanDelete.mutate(idMembershipPlan)
  }

  return (
    // @ts-ignore
    <Page className={classes.root} title="Listagem de Planos">
      <AccessControl
        rule={'membros.list'}
        // @ts-ignore
        yes={() => (
          <div>
            {membershipPlan && membershipPlan.length > 0 ? (
              <Typography variant="body2" align="left" gutterBottom>
                Total de dados encontrados: <b>{membershipPlan.length}</b>
              </Typography>
            ) : null}
            {undefined !== membershipPlan && membershipPlan.length ? (
              <TableContainer component={Paper} className={classes.paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Imagem</TableCell>
                      <TableCell align="center">Nome</TableCell>
                      <TableCell align="center">Descrição</TableCell>
                      <TableCell align="center">Data Início</TableCell>
                      <TableCell align="center">Data Fim</TableCell>
                      <TableCell align="center">N⁰ Ingressos</TableCell>
                      <TableCell align="center">
                        Dia geração da cobrança
                      </TableCell>
                      <TableCell align="center">Dias Inadimplência</TableCell>
                      <TableCell align="center">Membros</TableCell>
                      {/* <TableCell align="center">Check-In</TableCell> */}
                      {/* <TableCell align="center">Valor</TableCell> */}
                      <TableCell align="right">Ações</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {membershipPlan.map((n: MembershipPlan) => {
                      return (
                        <TableRow hover key={n.id}>
                          <TableCell component="th" scope="row">
                            <Link
                              color="inherit"
                              component={RouterLink}
                              to={`listaMembro?planoId=${n.id}`}
                              variant="h6"
                            >
                              <Avatar
                                className={classes.avatar}
                                src={getImageUrls(n.imagem)?.thumbnailUrl}
                                variant={'square'}
                              >
                                {getInitials(n.nome)}
                              </Avatar>
                            </Link>
                          </TableCell>
                          <TableCell align="center" component="th" scope="row">
                            {n.nome}
                          </TableCell>
                          <TableCell align="center" component="th" scope="row">
                            {n.descricao}
                          </TableCell>
                          <TableCell align="center" component="th" scope="row">
                            {toLocalDateFormat(n.dataHoraInicio)}
                          </TableCell>
                          <TableCell align="center" component="th" scope="row">
                            {toLocalDateFormat(n.dataHoraFim)}
                          </TableCell>
                          <TableCell align="center" component="th" scope="row">
                            {n.numeroIngressos}
                          </TableCell>
                          <TableCell align="center" component="th" scope="row">
                            {n.diaVencimento}
                          </TableCell>
                          <TableCell align="center" component="th" scope="row">
                            {n.diasInadimplencia}
                          </TableCell>
                          <TableCell align="center" component="th" scope="row">
                            {n.totalMembros}/
                            {n.limiteMembros ? n.limiteMembros : '-'}
                            {/* {n.limiteMembros} */}
                          </TableCell>
                          {/* <TableCell align="center" component="th" scope="row">
                            {n.checkIn}
                          </TableCell> */}
                          {/* <TableCell align="center" component="th" scope="row">
                            {n.planoValor}
                          </TableCell> */}
                          <TableCell align="right">
                            <AccessControl
                              rule={'membros.edit'}
                              // @ts-ignore
                              yes={() => (
                                <IconButton
                                  size="small"
                                  aria-label="Edit"
                                  component="a"
                                  onClick={() =>
                                    history.push(
                                      `/members/cadastroPlano/${n.id}`
                                    )
                                  }
                                >
                                  <Tooltip title="Editar">
                                    <EditIcon />
                                  </Tooltip>
                                </IconButton>
                              )}
                            />
                            <AccessControl
                              rule={'membros.delete'}
                              // @ts-ignore
                              yes={() => (
                                <IconButton
                                  size="small"
                                  aria-label="Delete"
                                  onClick={() => handleDeleteClick(n.id)}
                                >
                                  <Tooltip title="Excluir">
                                    <DeleteIcon />
                                  </Tooltip>
                                </IconButton>
                              )}
                            />
                          </TableCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <EmptyFirstMembershipPlan />
            )}
          </div>
        )}
      />
    </Page>
  )
}
MembershipPlanList.propTypes = {
  classes: PropTypes.object.isRequired
}
// @ts-ignore
const mapStateToProps = (state) => {
  return {
    membershipPlan: state.membershipPlan,
    estabelecimentoId: state.usuarioEstabelecimento.estabelecimentoId
  }
}

// @ts-ignore
const connectedMembershipPlanPage = withRouter(
  // @ts-ignore
  connect(mapStateToProps, null, null, {
    pure: false
  })(withStyles(styles)(MembershipPlanList))
)
export { connectedMembershipPlanPage as MembershipPlanList }
